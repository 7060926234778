import React from "react";
import { Link, graphql } from "gatsby";
import { Container, Row, Col, Button } from "react-bootstrap";
import Pagination from 'react-bootstrap/Pagination';

import Footer from "../components/common/footer";
import ShareSocial from "../components/common/shareSocial";
import Header from "../components/common/header";

class BlogPostTemplate extends React.Component {
    render() {
        let post = this.props.data.markdownRemark;
        let tags = post.frontmatter.tags;

        const { previous, next } = this.props.pageContext;
        
        return (
            <div className={"post-page"}>
                <Header pageName={post.frontmatter.title} />
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className={"post-header"}>
                                <h2>{post.frontmatter.title}</h2>

                                <p>By <a href={"https://hallak.io"}><span>{post.frontmatter.author}</span></a> on {post.frontmatter.date}</p>

                                <ul className={"post-tags"}>
                                    {tags.map((tag) => {
                                        return (
                                            <li key={tag}>
                                                <Link to={"/tags/" + tag.toString().toLowerCase()} className={"post-tag-link"}>
                                                    <Button variant="outline-dark">#{tag}</Button>
                                                </Link>
                                            </li>
                                        )
                                    })}
                                </ul>

                            </div>
                        </Col>
                    </Row>
                    <Row className={"post-body"}>
                        <Col md={11}>
                            <div dangerouslySetInnerHTML={{ __html: post.html }} />
                        </Col>
                        <Col md={1} xs={12}>
                            <ShareSocial postTitle={post.frontmatter.title} pageSlug={this.props.path} />
                        </Col>
                    </Row>
                    <Row className={"post-footer"}>
                        <Col md={12}>
                            <Pagination>
                                {previous && (
                                    <Pagination.Item href={previous.fields.slug}>
                                        ← {previous.frontmatter.title}
                                    </Pagination.Item>
                                )}
                                {next && (
                                    <Pagination.Item href={next.fields.slug}>
                                        {next.frontmatter.title} →
                                    </Pagination.Item>
                                )}
                            </Pagination>
                        </Col>
                    </Row>
                    <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
                    <script>
                    {`
                        (adsbygoogle = window.adsbygoogle || []).push({
                            google_ad_client: "ca-pub-2386065354514461",
                            enable_page_level_ads: true
                        });
                    `}
                    </script>
                </Container>
                <Footer />
            </div>
        )
    }
}

export default BlogPostTemplate

export const pageQuery = graphql`
query BlogPostBySlug($slug: String!) {
    site {
        siteMetadata {
            title
            author
        }
    }

    markdownRemark(fields: { slug: { eq: $slug } }) {
        id
        excerpt(pruneLength: 160)
        html
        frontmatter {
            title
            author
            tags
            date(formatString: "MMMM DD, YYYY")
            description
        }
    }
}
`