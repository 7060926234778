import React from "react";

class ShareSocial extends React.Component {
    render() {
        var tweet_post_path = "https://twitter.com/intent/tweet/?text=Check%20out%20" + this.props.postTitle.toString().replace(" ", "%20") + "%20by%20Mohamad%20Hallak%20(@mhmdhallak)&url=https://hallak.io"+ this.props.pageSlug + "&via=mhmdhallak";
        var facebook_post_path = "https://www.facebook.com/sharer/sharer.php?u=https://hallak.io"+this.props.pageSlug;
        var linkedin_post_path = "https://www.linkedin.com/shareArticle?mini=true&url=https://hallak.io"+this.props.pageSlug+"&title="+this.props.postTitle.toString().replace(" ", "%20")+"&source=https://hallak.io"

        return (
            <div className={"social-share"}>
                <div className={"social-title"}>
                    <h5>Share</h5>
                </div>
                <ul className={"social-icons"}>
                    <li><a href={tweet_post_path}><i aria-label={"Twitter"} className={"fab fa-twitter"}></i></a></li>
                    <li><a href={facebook_post_path}><i aria-label={"Facebook"} className={"fab fa-facebook-f"}></i></a></li>
                    <li><a href={linkedin_post_path}><i aria-label={"LinkedIn"} className={"fab fa-linkedin-in"}></i></a></li>
                </ul>
            </div>
        )
    }
}

export default ShareSocial;